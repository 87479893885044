<template>
  <div class="app-default-style">
    <el-config-provider :locale="locale">
      <RouterView />
    </el-config-provider>
  </div>
  <!--  <el-config-provider :locale="locale" :class="appStore.themeDarkMode ? 'theme-dark' : 'theme-light'">-->
</template>

<script setup>
// ElementPlus 的国际化配置
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { useAppStore } from '@/stores/app'
import { RouterView } from 'vue-router'
import '@/styles/theme/index.scss'

const appStore = useAppStore()
const locale = ref(null)

const languageMap = {
  zh: () => import('element-plus/dist/locale/zh-cn.mjs'),
  en: () => import('element-plus/dist/locale/en.mjs'),
  ko: () => import('element-plus/dist/locale/ko.mjs'),
  my: () => import('element-plus/dist/locale/en.mjs'),
  th: () => import('element-plus/dist/locale/en.mjs'),
  vi: () => import('element-plus/dist/locale/en.mjs'),
}

async function loadLocale() {
  const loadLocaleModule = languageMap[appStore.language] || languageMap.ko
  locale.value = (await loadLocaleModule()).default
}


onMounted(async () => {
  await loadLocale()
  // console.error(locale.value)
  window.addEventListener('contextmenu', disableRightClick)

  // 监听窗口大小变化，并更新窗口宽度的响应式数据
  appStore.windowWidth = window.innerWidth
  appStore.windowHeight = window.innerHeight
  window.addEventListener('resize', reloadWindowSize)
  window.addEventListener('click', onClick)
})

onUnmounted(() => {
  window.removeEventListener('contextmenu', disableRightClick)
  window.removeEventListener('resize', reloadWindowSize)
  window.removeEventListener('click', onClick)
})

function disableRightClick(event) {
  event.preventDefault() // 阻止默认右键菜单
}

function reloadWindowSize() {
  appStore.windowWidth = window.innerWidth
  appStore.windowHeight = window.innerHeight
}

function onClick() {
  appStore.clickedWeb = true
}
</script>

<style>
.app-default-style {
  font-family: 'Helvetica Neue', Roboto, Arial, 'Droid Sans', sans-serif;
  color: var(--txt-default-color);
  width: 100%;
  height: 100%;
}
</style>
